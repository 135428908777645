.product-item {

   &.has-dropdown {
     .navbar-dropdown {
       display: none;
     }
     &.is-active {
       .navbar-dropdown {
         display: block;
       }
      }
    }
 }
