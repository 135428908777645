.home {
    color: black;
}

.img {
    border: #ffffff solid 4px;
}

svg {
    width: 15px;
}

html {
	scroll-behavior: smooth;
}

.navhome {
    right: unset !important;
    bottom: 50vh !important;
}

figure {
    max-width: 600px;
}


.box {
    background-color: rgba(238, 238, 238, 0.692) !important;
}

.hero-body {
    color: black;
}

.bckgrnd {
    background-image: url("../assets/collet-pano.jpg");
    background-position: left;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

.daily-food-tiles {
    background-color: rgb(255, 255, 255);
    border: rgb(255, 255, 255) solid 1px;
    border-radius: 5px;
    margin: 0 4px;
}

.daily-food-tiles:hover {
    background-color: rgb(168, 168, 168);
    border: rgb(0, 0, 0) solid 1px;
}

.content-tiles {
    background: none !important;
    background-color: rgb(250, 250, 250) !important;
    margin: 0;
    padding: 1rem;
    border-radius: 8px;

    >* {
        color:rgb(0, 0, 0) !important;
        background-color: rgb(255, 255, 255) !important;
    }
}

.contact-home {
    text-decoration: underline;
}

.mapshop {
    width: 400px;
}

.phonenumb {
    width: max-content;
}

.contact-content > section {
    border: rgb(153, 153, 153) 1px solid;
    background-color: rgb(238, 238, 238);
    border-radius: 5px;
    padding-top: 6rem;
    img {
        border-radius: 10px;
        padding: 4px;
    }
}

.home-desc {
    height: fit-content;
}

.querry-status {
    color: red;
    font-weight: bold;
}

.localprod {
    width: 360px;
}

.img-localprod {
    width: 360px;
}

.allprods {
    background-image: url("../assets/collet-pano.jpg");
    background-repeat: no-repeat;
    border: 4px solid white;
    border-radius: 8px;
    background-position: top;
    background-attachment: fixed;
    >* {
        margin: 0px 4px;
    }
}

.img-desc-name {
    position: absolute;
    bottom: 0rem;
    color: rgb(29, 1, 1);
    border: solid white 1px;
    background-color: rgb(255, 255, 255);
    padding: 4px;
    width: fit-content;
    border-radius: 4px;
}

strong {
    font-weight: bold;
}

.title-content {
    padding: 1rem;
}